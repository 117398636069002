<template>
    <div class="trusted-section">
        <div class="trusted-container">
            <img :src="linebreak" class="trusted-divider">
                <a href="https://www.trustpilot.com/review/panasgold.com" target="_blank" style="text-decoration: inherit; color: inherit;">
                    <div>
                        <img :src="logo">
                            <div class="trusted-text" >
                                <span class="teal">Reviews</span> from the Community
                            </div>
                        </div>
                </a>
                <img :src="linebreak" class="trusted-divider">
                <div class="trusted-stars" >
                    <a href="https://www.trustpilot.com/review/panasgold.com" target="_blank" data-v-818b24c0="" style="text-decoration: inherit; color: inherit;">
                        <img :src="stars" class="trusted-stars">
                    </a>
                </div>
                <swiper
                    :slides-per-view="3"
                    :space-between="0"
                    :loop="true"
                    :pagination="false"
                    :navigation="true"
                    :breakpoints="breakpoints"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    >
                    <swiper-slide
                        v-for="r in reviews"
                        :key="r.name"
                        class="swiper-slide"
                    >
                    
                        <a href="https://www.trustpilot.com/review/panasgold.com" target="_blank"  style="text-decoration: inherit; color: inherit;">
                            <div class="card">
                                <img :src="r.img" class="image">
                                <div class="name">{{ r.name }}</div>
                                <div class="review">{{ r.review }}</div>
                            </div>
                        </a>
                    </swiper-slide>
                </swiper>
         </div>
    </div>
</template>


<script>

import { Navigation, Pagination } from 'swiper'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])


export default ({
    components: {
      Swiper,
      SwiperSlide,
    },
    methods: {
        getImageUrl (imageId) {
        return `https://picsum.photos/600/400/?image=${imageId}`
        },
        onSwiper () {
        // console.log(swiper)
        },
        onSlideChange () {
        // console.log('slide change')
        }
    },
    setup() {

        return {
            linebreak: require('@/assets/linebreak.png'),
            logo: require('@/assets/trustpilotlogo.png'),
            stars: require('@/assets/trustpilotstars.png'),
            reviews: [

                {
                    name: 'Adriana Perez',
                    review: 'rápida y confiable como siempre',
                    img: require('@/assets/adriana_perez.png'),
                },
                {
                    name: 'Adriana Díaz',
                    review: 'Personas muy responsables y serias , tengo años con ellos y nunca he tenido malas experiencias, recomendados 100%.',
                    img: require('@/assets/adriana_diaz.png'),
                },
                {
                    name: 'Franklin Javier',
                    review: 'Transacciones rápidas y seguras. 100% recomendados',
                    img:  require('@/assets/franklin.png'),
                },
                {
                    name: 'Vccg',
                    review: 'Excelente servicio',
                    img:  require('@/assets/vccg.png'), 
                },
            ],
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },

                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },

                1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            }
        }
    },
})
</script>


<style lang="css">

.swiper-button-prev, .swiper-button-next {
    color: #00e4d4 !important;
}


.trusted-text {
    font-weight: 700;
    font-size: 32px
}

@media (max-width: 800px) {
    .trusted-text{
        font-size:24px
    }
}

.teal {
    color: #3bf3ff
}

.trusted-stars {
    width: 240px;
    height: auto;
    margin: 16px auto;

}

.card {
    margin: 0 auto;
    background-color: #2c3532;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    padding: 16px
}

.card .image {
    border-radius: 50%
}

.card .name {
    font-size: 24px;
    color: #13bc9c;
    font-weight: 700
}

.card .review {
    font-weight: 700;
    text-transform: uppercase;
    height: 4em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.trusted-container {
    text-align: center;
    max-width: 1060px;
    width: 100%;
    margin: 0 auto
}

.trusted-divider{
    margin-bottom: 8px;
    max-width: 100%;
}

.trusted-section {
    padding-top: 32px
}
</style>